
import { defineComponent } from 'vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import type { BreadcrumbsLink, FlagCountry } from '@/definitions/shared/types'
import TmAutoSizePanelMobileActivator from '@/components/shared/autoSize/panel/TmAutoSizePanelMobileActivator.vue'
import TmListChipRecipient from '@/components/shared/TmListChipRecipient.vue'
import CallsDetailsMoreActions from '@/components/pages/history/details/CallsDetailsMoreActions.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'

export default defineComponent({
  components: {
    TmCountry,
    TmListChipRecipient,
    TmAutoSizePanel,
    TmAutoSizePanelItemButton,
    TmAutoSizePanelMobileActivator,
    DetailsTableItem,
    DetailsTable,
    CallsDetailsMoreActions,
    PageContent,
  },
  setup() {
    const recipient = {
      type: 'contact',
      firstName: 'John',
      lastName: 'Doe',
      phone: '(756) 830-1636',
    }
    const breadcrumbs: BreadcrumbsLink[] = [
      {
        label: 'Outbound calls',
        name: 'base.history.outboundCalls',
      },
      {
        label: '62471259',
      },
    ]
    const country: FlagCountry = {
      name: 'United States',
      id: 'us',
    }
    return {
      breadcrumbs,
      recipient,
      country,
    }
  },
})
